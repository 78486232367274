<template>
  <div ref="wrapper" class="relative flex flex-col">
    <div
      ref="inputTarget"
      tabindex="0"
      :class="{ 'order-2': isAbove, 'order-1': !isAbove }"
      class="flex items-center"
      @click="open = !open"
    >
      <div
        class="flex h-field w-full cursor-pointer items-center border-y border-l bg-white text-g7-blue"
      >
        <input
          class="pointer-events-none size-full bg-white px-2 focus:outline-none sm:placeholder:text-g7-blue"
          :placeholder="placeholder"
          :value="dateFormatted"
          disabled
        />
      </div>
      <div
        class="flex h-field w-12 items-center justify-center border bg-white"
      >
        <IconsCalendar class="size-6 fill-g7-blue" />
      </div>
    </div>
    <div :class="{ 'order-1': isAbove, 'order-2': !isAbove }">
      <div
        v-if="show"
        ref="calendarTarget"
        class="z-[600] flex flex-col items-center rounded-sm border border-black bg-white shadow-lg hover:flex lg:absolute lg:right-0"
        :class="{ hidden: !open, '-translate-y-full': isAbove }"
      >
        <div class="flex w-full items-center justify-between bg-g7-blue">
          <span class="px-4 py-2 text-center text-sm font-black text-white">
            Wählen Sie den Zeitraum, in dem Sie verreisen möchten:
          </span>
          <div
            class="mr-4 flex size-5 cursor-pointer items-center justify-center rounded-full bg-white p-1"
            @click="close"
          >
            <IconsX class="size-3 fill-g7-blue" />
          </div>
        </div>
        <div class="p-4">
          <!-- eslint-disable vue/no-custom-modifiers-on-v-model -->
          <DatePicker
            v-model.range.string="model"
            :masks="masks"
            :columns="!lg ? 1 : 2"
            :initial-page="fromDate"
            :select-attribute="attribute"
            :min-date="new Date()"
          />
          <!-- eslint-enable vue/no-custom-modifiers-on-v-model -->
          <hr class="mx-8 mb-2 border border-g7-blue" />
          <div class="w-full text-center">
            <div class="hidden w-full lg:block">
              <span class="font-black text-g7-blue">
                Wählen Sie Ihre Reisedauer:
              </span>
              <div class="mt-2 flex flex-wrap justify-center">
                <div
                  v-for="(item, index) in options"
                  :key="index"
                  class="mx-2 my-1 flex cursor-pointer"
                >
                  <div
                    :class="{
                      '!hover:bg-[#1d52b2] !bg-[#1d52b2]': duration == item,
                    }"
                    class="whitespace-nowrap rounded-sm bg-g7-blue p-1 text-sm text-white hover:bg-[#071c43]"
                    @click="duration = item"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <G7Button
                class="mt-4 p-1"
                color="orange"
                label="Übernehmen"
                @click="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorMessage v-if="hasErrors" />
  </div>
</template>

<script setup lang="ts">
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import { useField } from "vee-validate";
import type { Ref } from "vue";
import type { DateRange, LabelValue } from "~~/types/form";

const props = defineProps({
  validation: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Reisezeit",
  },
  fromDate: {
    type: Object,
    default: null,
  },
  durations: {
    type: Object,
    default: null,
  },
});

const model = defineModel<Record<string, string>>({
  required: true,
  validator(value: DateRange) {
    return (
      validateDate(value.start, "-", /^\d{2}-\d{2}-\d{4}$/) &&
      validateDate(value.end, "-", /^\d{2}-\d{2}-\d{4}$/)
    );
  },
});

const { lg } = toRefs(useScreen);

const attribute = {
  highlight: {
    style: {
      backgroundColor: "#0f2b5d",
    },
    contentStyle: {
      color: "#ffffff",
    },
  },
};

const masks = ref({
  modelValue: "DD-MM-YYYY",
});
const isAbove = ref(false);
const duration: Ref<LabelValue | null> = ref(null);
const options = ref(props.durations.options);
const open: Ref<boolean> = ref(false);
const wrapper = ref<HTMLDivElement>();
const calendarTarget = ref<HTMLDivElement>();
const inputTarget = ref<HTMLDivElement>();
const show: Ref<boolean> = ref(true);

const emit = defineEmits(["update:modelValue", "set-duration"]);

const { meta } = useField(props.name ?? "to-do-name-input", props.validation, {
  syncVModel: true,
});

const hasErrors = computed(() => !meta.valid && meta.touched);

const dateFormatted = computed(() => {
  let value = "";
  if (model.value.start && model.value.end) {
    value += `${toLocaleString(model.value.start)} - ${toLocaleString(
      model.value.end
    )} `;
  }
  if (duration.value && duration.value.label) {
    value += `${duration.value.label}`;
  }
  return value;
});

const close = async () => {
  show.value = false;
  await nextTick();
  open.value = false;

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function toLocaleString(date: string): string {
  const [day, month, year] = date.split("-");
  const d = new Date(`${year}-${month}-${day}`);
  return d.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function diffInDays(startDate: string, endDate: string): number {
  const end = new Date(
    `${endDate.split("-")[2]}-${endDate.split("-")[1]}-${endDate.split("-")[0]}`
  );
  const start = new Date(
    `${startDate.split("-")[2]}-${startDate.split("-")[1]}-${
      startDate.split("-")[0]
    }`
  );
  const diff = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

onMounted(async () => {
  if (process.client) {
    await nextTick();
    window.addEventListener("resize", updateUseScreen);
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", updateUseScreen);
});

watch(model, () => {
  const days = diffInDays(model.value.start, model.value.end);
  options.value = props.durations.options.filter((element: LabelValue) => {
    const [first, second] = (element.value || "").split("-").map(Number);
    const maxDays = second === 0 ? first + 1 : second;
    return element.value === "-" || days >= maxDays ? element : null;
  });
  if (!options.value.includes(duration.value)) {
    duration.value = null;
  }
});

watch(duration, () => {
  emit("set-duration", duration.value);
});

watch(open, async () => {
  if (open.value) {
    show.value = true;
  }
  isAbove.value = false;
  await nextTick();
  isAbove.value = isBoundingBottom(calendarTarget.value);
  await nextTick();
  if (open.value && !isFullVisible(calendarTarget.value)) {
    wrapper.value?.scrollIntoView({ behavior: "smooth" });
    isAbove.value = false;
  }
});

watch(wrapper, () => {
  if (!wrapper) {
    return;
  }
  onClickOutside(wrapper.value, () => {
    open.value = false;
  });
});
</script>

<style scoped>
:deep(.vc-nav-popover-container) {
  background-color: #43a5d4;
  color: #ffffff;
}
:deep(.vc-title) {
  color: #0f2b5d;
}
:deep(.vc-nav-title:hover) {
  background-color: #071c43;
  color: white;
}
:deep(.vc-nav-arrow) {
  color: #ffffff;
}
:deep(.vc-nav-arrow:hover) {
  background-color: #071c43;
}
:deep(.vc-nav-title) {
  color: white;
}
:deep(.vc-nav-item.is-current) {
  color: #0f2b5d;
}
:deep(.vc-nav-item:hover) {
  background-color: #071c43;
  color: white;
}
:deep(.is-active) {
  background-color: white !important;
  color: #0f2b5d !important;
}
:deep(.vc-highlight-base-end) {
  background-color: #9faabe;
}
:deep(.vc-highlight-base-middle) {
  background-color: #9faabe;
}
:deep(.vc-highlight-base-start) {
  background-color: #9faabe;
}
:deep(.vc-content-content-outline) {
  outline-color: #071c43;
}
:deep(.vc-bordered) {
  border: none;
}
:deep(.vc-weeks) {
  margin-left: 4px;
  margin-right: 4px;
}
:deep(.vc-weekdays) {
  margin-bottom: 6px;
  margin-top: 6px;
}
:deep(.in-month) {
  border-width: 1px;
  padding: 8px;
  border-collapse: collapse;
}
@media (max-width: 1024px) {
  :deep(.in-month) {
    padding: 0px;
  }
}
:deep(.is-today) {
  border-width: 1px;
  border-color: #071c43;
}
:deep(.is-today > .vc-day-content) {
  background-color: #838da1;
  color: white;
}
:deep(.on-right.in-month) {
  border-right-width: 2px;
}
:deep(.on-bottom.in-month) {
  border-bottom-width: 2px;
}
:deep(.on-top.in-month) {
  border-top-width: 2px;
}
:deep(.on-left.in-month) {
  border-left-width: 2px;
}
:deep(.day-from-end-1) {
  border-right-width: 1px;
}
:deep(.in-prev-month) {
  border-bottom-width: 1px;
}
:deep(.in-next-month.on-bottom) {
  border-top-width: 1px;
}
:deep(.day-1):not(.on-left) {
  border-left-width: 1px;
}
:deep(.vc-day-layer) {
  color: #071c43;
}
</style>
