<template>
  <div class="w-full">
    <div
      class="mb-6 flex w-full flex-col items-center lg:flex-row lg:items-end"
    >
      <div
        class="flex w-full flex-col items-center py-2 text-g7-blue md:w-1/2 md:items-end md:py-0 lg:w-full lg:flex-row"
      >
        <div class="my-2 w-full sm:my-0 lg:mr-8">
          <FieldOceanRiverToggler v-model="cruisingArea" name="cruisingArea" />
          <FieldComboboxByValue
            v-model="destination"
            :items="destinations?.options"
            :disabled="pending"
          />
        </div>

        <FieldComboboxByValue
          v-model="cruiseline"
          class="my-2 w-full md:my-0 lg:mr-8"
          :items="cruiselines?.options"
          :disabled="pending"
        />
        <FieldDatepickerRange
          v-model="date"
          placeholder="Zeitraum & Dauer"
          :durations="durations"
          class="my-2 w-full md:my-0 lg:mr-8"
          @set-duration="setDuration"
        />
      </div>

      <G7Button
        class="h-field w-full px-2.5 !text-xl md:w-1/2 md:!text-lg lg:ml-4 lg:mr-10 lg:w-auto"
        :label="searchOptions?.search_filter_data_fields.action_button.label"
        @click="search"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CruiseFinderResponse } from "~~/types/response";
import type { DateRange } from "~~/types/form";
import type { CruiseFinder } from "~/types/search";

const props = defineProps<{
  links?: string;
}>();

const pending = ref(false);
const cruisingArea = ref("hochsee");
const { data: searchOptions } = await useFetch<CruiseFinderResponse>(
  props.links ??
    apiUrl(`/catalog/homepages/go7seas/cruise-finder?cruiseregion=hochsee`)
);

const dataFields = searchOptions.value?.search_filter_data_fields;
const destinations = ref(dataFields?.cruise_region_selection);
const cruiselines = ref(dataFields?.cruise_line_selection);
const durations = ref(dataFields?.duration_range_selection);

const destination = ref(destinations?.value?.value ?? "-");
const cruiseline = ref(cruiselines?.value?.value ?? "-");
const duration = ref(durations?.value?.value ?? null);
const date: Ref<DateRange> = ref({
  start: dataFields?.arrival_date_field?.value ?? "",
  end: dataFields?.departure_date_field?.value ?? "",
});

function getSearchOptions(): Promise<CruiseFinderResponse> {
  return $fetch<CruiseFinderResponse>(
    apiUrl(
      `/catalog/homepages/go7seas/cruise-finder?cruising-area=${
        cruisingArea.value
      }&cruiseregion=${destination.value}&cruiseline=${
        cruiseline.value
      }&departure-date=${
        date.value.start ? date.value.start : "-"
      }&arrival-date=${date.value.end ? date.value.end : "-"}&duration=${
        duration.value ?? "-"
      }`
    ),
    { headers: defaultApiHeaders() }
  );
}

function search() {
  const region =
    destination.value === "-" ? cruisingArea.value : destination.value;
  const range =
    (date.value.start !== "" ? `/abreise/${date.value.start}` : "") +
    (date.value.end !== "" ? `/rueckkehr/${date.value.end}` : "") +
    (duration.value ? `/dauer/${duration.value}` : "");
  const url = `/suche/reederei/${cruiseline.value}/region/${region}${range}`;
  useRouter().push(url);
}

function setDuration(event) {
  if (event !== null) {
    duration.value = event.value;
  } else {
    duration.value = null;
  }
}

watch([cruisingArea, cruiseline, destination, date, duration], async () => {
  pending.value = true;
  const searchOptions = await getSearchOptions();
  setSelections(searchOptions.search_filter_data_fields);
  setValues(searchOptions.search_filter_data_fields);
  pending.value = false;
});

function setValues(dataFields: CruiseFinder) {
  if (dataFields.cruise_region_selection.value !== destination.value) {
    destination.value = dataFields?.cruise_region_selection.value;
  }

  if (dataFields.cruise_line_selection.value !== cruiseline.value) {
    cruiseline.value = dataFields?.cruise_line_selection.value;
  }

  if (dataFields.duration_range_selection.value !== duration.value) {
    duration.value = dataFields?.duration_range_selection.value;
  }

  if (dataFields.departure_date_field.value !== date.value.start) {
    date.value.start =
      dataFields.departure_date_field.value === "-"
        ? ""
        : dataFields?.departure_date_field.value;
  }

  if (dataFields.arrival_date_field.value !== date.value.end) {
    date.value.end =
      dataFields.departure_date_field.value === "-"
        ? ""
        : dataFields?.departure_date_field.value;
  }
}

function setSelections(dataFields: CruiseFinder) {
  destinations.value = dataFields.cruise_region_selection;
  cruiselines.value = dataFields.cruise_line_selection;
  durations.value = dataFields.duration_range_selection;
}
</script>
